import { PROD, SENTRY_INGEST_URL } from "$lib/utils/config";
import { Replay, handleErrorWithSentry } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";

const ignoreErrors = [
	/undefined(.*)offsetHeight(.*)/i,
	/Unable to preload CSS for(.*)/i,
	/(.*)gCrWeb(.*)/i,
	/Load failed/i,
	/Failed to fetch/i,
	/NetworkError when attempting to fetch resource./i,
	/Cancelled/i,
	/The network connection was lost./i,
	/Importing a module script failed./i,
	/error loading dynamically imported module/i,
	/^No error$/i,
	/__show__deepen/i,
	/_avast_submit/i,
	/Access is denied/i,
	/anonymous function: captureException/i,
	/Blocked a frame with origin/i,
	/can't redefine non-configurable property "userAgent"/i,
	/change_ua/i,
	/console is not defined/i,
	/cordova/i,
	/DataCloneError/i,
	/Error: AccessDeny/i,
	/event is not defined/i,
	/feedConf/i,
	/ibFindAllVideos/i,
	/myGloFrameList/i,
	/SecurityError/i,
	/MyIPhoneApp/i,
	/snapchat.com/i,
	/vid_mate_check is not defined/i,
	/win\.document\.body/i,
	/window\._sharedData\.entry_data/i,
	/window\.regainData/i,
	/ztePageScrollModule/,
	/A network error occurred./,
	/Can't find variable: IntersectionObserver/,
	/Can't find variable: msDiscoverChatAvailable/,
	/undefined is not an object \(evaluating 'ceCurrentVideo.currentTime'\)/,
	/We could not retrieve data from the specified Element. Please make(.*)/i,
	/undefined is not an object \(evaluating 'window.messageHandlers.mailbox'\)/i,
	/网络连接已中断/i,
	/ネットワーク接続が切れました/i,
];

Sentry.init({
	dsn: SENTRY_INGEST_URL,
	tracesSampleRate: PROD ? 0.05 : 0.1,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: PROD ? 1 : 0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [new Replay()],

	beforeSend(event, hint) {
		if (hint.originalException instanceof Error) {
			if (ignoreErrors.some((rx) => rx.test(hint.originalException?.toString() ?? ""))) {
				return null;
			}
			return event;
		}

		return null;
	},
	ignoreErrors,
	denyUrls: [
		/webkit-masked-url:\/\/hidden\//,
		/extensions\//i,
		/^chrome:\/\//i,
		/^chrome-extension:\/\//i,
		/refresh\(@sveltejs\/svelte-virtual-list\/VirtualList\)/i,
		/setup_preload\(@sveltejs\/kit\/src(.*)/i,
		/<anonymous>\(@sveltejs\/kit\/src(.*)/i,
	],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
